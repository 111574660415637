import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/indexLayout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

/*
 Farbe für Vogl-Immobilien: #289dcc
 */

const IndexPage = props => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        <section className="hero-section">
          <div className="hero-texts">
            {
              /*
                <Img
                  fluid={props.data.imageFirst.childImageSharp.fluid}
                  className="sosc-logo"
                  alt="sosc logo"
                />
              */
            }
            <p className="sosc-header">
              Vogl-Immobilien
              <br />
              Ihr Immobilienmakler im Märkischen Kreis
            </p>
            <p>
              Sehr geehrte Besucher,
              <br /><br />
              ich freue mich, Sie auf meiner Seite von Vogl-Immobilien begrüßen zu können.
              Bitte schauen Sie sich um. Aktuelle Immobilienangebote zum Kauf finden Sie unter Immobilien.
              Für Fragen oder Informationen stehe ich Ihnen gerne zur Verfügung.
              <br /><br />
              Mit freundlichem Gruß
              <br />
              Frank Vogl
            </p>
            <Link className="btn-hero-green" to="/immobilien">Immobilien → </Link>
          </div>
          <Img
            fluid={props.data.imageOne.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
        </section>
      </div>
      <div className="container">
        <section className="hero-section2 ">
          <Img
            fluid={props.data.imageTwo.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
          <div className="hero-texts">
            <p className="hero-header">Kontakt</p>
            <div className="dash" />
            <p>
              Sie haben Fragen oder benötigen weitere Informationen?
              Sie haben eine Immobilie zu verkaufen und benötigen eine realistische Einschätzung zum Verkaufspreis?
              Ich stehe Ihnen gerne zur Verfügung. Nehmen Sie gerne Kontakt mit mir auf.
            </p>
            <Link className="btn-hero-green" to="/kontakt">Kontakt → </Link>
          </div>
        </section>
      </div>

      <div className="container">
        <section className="hero-section3">
          <div className="hero-texts">
            <p className="hero-header">Kundenbewertungen</p>
            <div className="dash" />
            <p>
              Wie liefen bisherige Immobilienverkäufe ab?
              Sie möchten mir eine Bewertung hinterlassen oder einfach nur Lob oder Kritik loswerden?
              Sehen Sie sich die Kundenbewertungen an.
            </p>
            <Link className="btn-hero-green" to="/kundenbewertungen">Kundenbewertungen → </Link>
          </div>
          <Img
            fluid={props.data.imageThree.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
        </section>
      </div>
    </div>
  </Layout>
)

export const firstSiteImages = graphql`
  fragment firstSiteImages on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageFirst: file(relativePath: { eq: "images/logo_grad.png" }) {
      ...firstSiteImages
    }
    imageOne: file(relativePath: { eq: "images/undraw_for_sale_viax.png" }) {
      ...firstSiteImages
    }
    imageTwo: file(relativePath: { eq: "images/undraw_contact_us_15o2.png" }) {
      ...firstSiteImages
    }
    imageThree: file(relativePath: { eq: "images/undraw_voting_nvu7.png" }) {
      ...firstSiteImages
    }
    imageFour: file(relativePath: { eq: "images/hero_connect_.png" }) {
      ...firstSiteImages
    }
  }
`

export default IndexPage
